import "./index.css"
import SecondSection from "./components/2secondSection";
import ThirdSection from "./components/3thirdSection";
import FourthSection from "./components/4fourthSection";
import FifthSection from "./components/5fifthSection";
import SixthSection from "./components/6sixthSection";
import SeventhSection from "./components/7seventhSection";
import EigthSection from "./components/8eigthSection";
import NinethSection from "./components/9ninethSection";
import TenthSection from "./components/10tenthSection";


function App() {
  return (
    <>
      <SecondSection />
      <div className="app-container">
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
      </div>
      <SeventhSection />
      <EigthSection />
      <NinethSection />
      <TenthSection />
    </>
  );
}

export default App;
