import InfoWithIcon from "./common/infoWithIcon"

export default function SixthSection() {
  const allServices = [
    {
      icon: "",
      title: "Filial de BO4 Toronto",
      text: "Currículo con estándares internacionales para la enseñanza de Jiu Jitsu."
    },
    {
      icon: "",
      title: "Destacada Academia de Jiu Jitsu en Colombia",
      text: "Clasificada como una de las mejores escuelas de jiu jitsu en Colombia."
    },
    {
      icon: "",
      title: "Diversidad de clases",
      text: "En Body of Four Pereira podrás acceder a una variedad de clases para mejorar tú proceso en el Jiu Jitsu."
    },
  ]
  const title1 = "Eleva tú experiencia de Jiu Jitsu en Pereira"

  return (
    <div
      className="justify-content-start align-items-center"
      style={{ padding: 30, marginTop: 30, marginBottom: 50 }}
    >
      <h1 style={{ marginBottom: 50, textAlign: "center" }}>{title1}</h1>
      <br />
      <div className="container-fluid">
        <div className="row">
          {
            allServices.map((item, index) => (
              <div key={`${item.title}${index}`} className="col-md-12 col-lg-4" style={{ paddingLeft: 0, paddingRight: 20 }}>
                <InfoWithIcon
                  icon={item.icon}
                  title={item.title}
                  text={item.text}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
