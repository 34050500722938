export default function StyledImage({ url = "https://media.istockphoto.com/id/1000301308/es/vector/etiqueta-engomada-del-smiley-papel-etiqueta-de-emoticon-cara-feliz-vector.jpg?s=612x612&w=0&k=20&c=UQTKdkQDuZGViNbux7mlLByBhiFeyHkRGwF5PFQAVPI=", size }) {
  return (
    <div>
      <img
        src={url}
        alt=""
        style={{ width: "100%", borderRadius: "5%" }}
      />
    </div>
  )
}