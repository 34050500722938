import InfoWithIcon from "./common/infoWithIcon"
import S3_ASSETS from './common/constant';


export default function FourthSection() {
  const allServices = [
    {
      icon: "kimono1.png",
      title: "Jiu Jitsu Todos Los Niveles",
      text: "Aprende Jiu Jitsu adaptado para todos los niveles, desde iniciantes hasta avanzados"
    },
    {
      icon: "kimono2.png",
      title: "Fitness y Acondicionamiento",
      text: "Potencia tu desempeño en Jiu Jitsu con nuestro programa de acondicionamiento físico diseñado específicamente para mejorar tu fuerza, resistencia y movibilidad."
    },
    {
      icon: "kimono3.png",
      title: "Preparación Competitiva",
      text: "Recibe entrenamiento especializado y orientación para destacar en competencias de jiu jitsu."
    },
  ]

  return (
    <div className="justify-content-start align-items-center" style={{ padding: 30, paddingTop: 50 }}>
      <h1>Services</h1>
      <br />
      <br />
      <div className="container-fluid">
        <div className="row">
          {
            allServices.map((item, index) => (
              <div key={`${item.title}${index}`} className="col-md-12 col-lg-4">
                <InfoWithIcon
                  icon={`${S3_ASSETS}/${item.icon}`}
                  title={item.title}
                  text={item.text}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
