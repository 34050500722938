export default function NinethSection() {
  return (
    <div style={{ height: 350, width: "100%", backgroundColor: "gray" }}>
      <iframe
        width="100%"
        height="350"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=El+Lago+Park+Cra.+7+%2323-20+Pereira,+Risaralda/@4.8148772,-75.7018393,16.23z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8e3887001d1bb0f1:0x27febf8185203f6f!2m2!1d-75.6988776!2d4.8147882?entry=ttu&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
        <a href="https://www.gps.ie/">gps tracker sport</a>
      </iframe>
    </div>
  )
}