import StyledImage from "./common/styledImage"
import parse from 'html-react-parser';
import S3_ASSETS from "./common/constant"


export default function EigthSection() {
  const title1 = "About Body of Four Colombia"
  const text = "Welcome to Body of Four Colombia. We are a jiu jitsu academy located in Pereira, RIS. With a passion for martial arts and a dedication to helping our students reach their full potential, we provide a supportive and inclusive environment for practitioners of all levels."
  const card_image = `${S3_ASSETS}/Jiu+jitsu+1.jpg`

  return (
    <div className="container pt-5 pb-5">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-5">
          <StyledImage
            url={card_image}
            size={100}
          />
        </div>
        <div className="d-flex col-sm-12 col-md-6 align-items-center">
          <div>
            <h1>{title1}</h1>
            <br />
            <p style={{ textAlign: "justify" }}>{parse(text)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}