import Stack from 'react-bootstrap/Stack';
import parse from 'html-react-parser';
import S3_ASSETS from './common/constant';
import FirstSection from './1firstSection';


export default function SecondSection() {
  const title = "Aprende jiujitsu hoy"
  const p1 = "Mejora tus habilidades y únete a una comunidad que valora la <br /> marcialidad, la disciplina y el crecimiento"
  const button1 = "Únete a nosotros"
  const button2 = "Contacto"

  const imagen_fondo = `${S3_ASSETS}/Defensa+persona+jiu+jitsu.jpg`

  const main_background = {
    backgroundImage: `url("${imagen_fondo}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }
  const opacity_background = {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    backdropFilter: "blur(1px)"
  }

  return (
    <div style={main_background}>
      <div style={opacity_background}>
        <FirstSection />
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ padding: 30, height: 500 }}
        >
          <div className="text-center">
            <h1>{title}</h1>
            <br />
            <p className="first-section-p text-center">{parse(p1)}</p>
            <br />
            <Stack className="d-flex justify-content-center" direction="horizontal" gap={2}>
              <button className="btn btn-outline-dark" >
                {button1}
              </button>
              <button className="btn btn-dark">
                {button2}
              </button>
            </Stack>;
          </div>
        </div>
      </div>
    </div>
  )
}