import StyledImage from "./common/styledImage"
import S3_ASSETS from "./common/constant"


export default function FifthSection() {
  const allImages = [
    { url: "Inversion+jiu+jitsu.jpg" },
    { url: "Clase+de+Jiu+Jitsu.jpg" },
    { url: "Pase+de+Guardia.jpg" },
    { url: "Single+X.jpg" },
    { url: "Barra+de+rodilla+jiu+jitsu.jpg" },
    { url: "Toma+de+espalda.jpg" },
  ]

  return (
    <div className="justify-content-center align-items-center" style={{ padding: 30 }}>
      <div className="container">
        <div className="row">
          {
            allImages.map((item, index) => (
              <div key={`${item.url}${index}`} className="col-md-12 col-lg-4 pb-4">
                <StyledImage
                  url={`${S3_ASSETS}/${item.url}`}
                  size={200}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
