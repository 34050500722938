export default function InfoWithIcon({ icon, title, text }) {
  return (
    <div style={{
      paddingLeft: 15,
      paddingRight: 15,
    }}>
      {
        icon && (
          <div>
            <img
              src={icon}
              alt=""
              style={{ maxWidth: "50px", marginBottom: 25 }}
            />
          </div>
        )
      }
      <h3>{title}</h3>
      <br />
      <p>{text}</p>
    </div>
  )
}
