function TouchForm() {
  const inputSytle = {
    borderRadius: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.07)",
    color: "rgb(17, 24, 39)"
  }

  return (
    <div className="container">
      <div className="row pb-3">
        <div className="col">
          <small>Name</small>
          <input style={inputSytle} className="form-control" type="text" />
        </div>
        <div className="col">
          <small>Email</small>
          <input style={inputSytle} className="form-control" type="text" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <small>Message</small>
          <textarea rows={8} style={{ ...inputSytle, resize: "none" }} className="form-control" type="text" />
        </div>
      </div>
    </div>
  )
}


export default function TenthSection() {
  const title1 = "Get in touch"
  const text = "Send us a message and we'll get back to you as soon as we can."

  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-sm-12 col-md-6 d-flex align-items-center">
          <div>
            <h1>{title1}</h1>
            <br />
            <p>{text}</p>
          </div>
        </div>
        <div className="d-flex col-sm-12 col-md-6 align-items-center">
          <TouchForm />
        </div>
      </div>
    </div>
  )
}