import Carousel from 'react-bootstrap/Carousel';


function CarrouselExample() {
  const testimonies = [
    {
      title: "Luis",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere nesciunt ipsum libero veritatis, quas odit. Similique, vero, neque libero labore eveniet soluta provident repellendus qui aut cum perferendis est repellat.",
      pic: "https://mui.com/static/images/avatar/1.jpg"
    },
    {
      title: "Jane",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere nesciunt ipsum libero veritatis, quas odit. Similique, vero, neque libero labore eveniet soluta provident repellendus qui aut cum perferendis est repellat.",
      pic: "https://mui.com/static/images/avatar/3.jpg"
    }
  ]

  const picStyle = {
    borderRadius: "50%",
    width: 80,
    marginBottom: 30
  }

  return (
    <Carousel>
      {
        testimonies.map(item =>
          <Carousel.Item key={item.title} interval={3500}>
            <div className='testimony'>
              <img style={picStyle} src={item.pic} alt="" />
              <p>{item.text}</p>
              <h5>-{item.title}</h5>
            </div>
          </Carousel.Item>
        )
      }
    </Carousel>
  );
}

export default CarrouselExample;