import StyledImage from "./common/styledImage"
import parse from 'html-react-parser';
import S3_ASSETS from "./common/constant";


export default function ThirdSection() {
  const h1_ = "Domina el arte del jiu jitsu con Body of Four Pereira"
  const p1 = "En el corazón de Pereira, Ris, nuestra academia te invita a sumergirte en un entrenamiento idóneo en Jiu Jitsu, ¡independientemente de tu experiencia! Con la orientación de nuestros profesores, podrás pulir tus habilidades y alcanzar nuevas alturas en este deporte fascinante. "

  const card_image = `${S3_ASSETS}/Guardia+X.jpg`

  return (
    <div
      className="d-flex justify-content-start align-items-center"
      style={{ paddingTop: 100 }}
    >
      <div style={{ paddingLeft: 30, paddingRight: 30, width: "100%" }}>
        <h1 style={{ textAlign: "center" }}>{parse(h1_)}</h1>
        <br />
        <br />
        <p className="third-section-p">{parse(p1)}</p>
        <div className="d-flex justify-content-center align-items-center pt-5" style={{ width: "100%" }}>
          <StyledImage
            url={card_image}
          />
        </div>
      </div>
    </div>
  )
}